import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/church',
    name: 'church',
    component: () => import('../views/Church.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/Users.vue')
  },
  {
    path: '/members',
    name: 'members',
    component: () => import('../views/Members.vue')
  },
  {
    path: '/attendance',
    name: 'attendance',
    component: () => import('../views/Attendance.vue')
  },
  {
    path: '/accountsettings',
    name: 'accountsettings',
    component: () => import('../views/AccountSettings.vue')
  },
  {
    path: '/manage',
    name: 'manage',
    component: () => import('../views/ManageEvent.vue')
  },
  {
    path: '/event',
    name: 'event',
    component: () => import('../views/EventAttendance.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
