import axios from "axios";

window.axios = axios;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://api.jilcwseeo.org/api/';
//axios.defaults.baseURL = 'http://localhost:5000/api/';

axios.interceptors.request.use(config=>{
    config.headers.common = {
        "Content-Type":"application/json",
        Accept:"application/json"
    }
    return config;
})